.CGUTitle {
  text-align: center;
}

.CGUWrapper {
  margin-top: 20px;
  height: 50vh;
  overflow-y: scroll;
}

.Article {
  margin-bottom: 1em;
}

.ArticleTitle {
  margin-bottom: 0.5em;
}
.ArticleSubtitle {
  margin-bottom: 0.5em;
}
.ArticleContent {
  margin-bottom: 0.5em;
}
.ArticleContent p {
  margin-bottom: 0.3em;
}
