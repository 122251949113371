.ContactForm {
  margin: 1.5em 0.3em;
}
.ContactFormTitle {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.Emoji {
  font-size: 1.5rem;
}
.ContactFormFields {
  margin: 2em;
  display: flex;
  flex-direction: column;
}
.Button {
  text-align: end;
}
.Success {
  margin-left: 0.2em;
  margin-bottom: 1em;
  color: green;
  font-size: 0.8rem;
}
.Error {
  margin-bottom: 1em;
  margin-left: 0.2em;
  color: red;
  font-size: 0.8rem;
}
