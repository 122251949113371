.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem a {
  color: #2a2f39;
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 7px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #e6404d;
  font-weight: bold;
  box-shadow: inset 5px 5px 10px #a6abbd, inset -5px -5px 10px #fafbff;
}

/* Small tablets */
@media (min-width: 620px) {
  .NavigationItem {
    margin: 10px 10px;
    padding: 10 30px;
  }
  .NavigationItem a {
    border: none;
    box-shadow: none;
    padding: 10px 0;
  }
  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    box-shadow: none;
    font-weight: bold;
    color: #e6404d;
  }
}
