* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "PT Sans", sans-serif;
  background: #f3f2f0;
}

@font-face {
  font-family: "Futura Extra Bold";
  src: local("Futura Extra Bold"),
    url(./assets/Futura_Extra_Bold.otf) format("truetype");
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}
