.Filter {
}
.Filter p {
  font-size: 0.9rem;
}
.Filter span {
  font-size: 1.5rem;
  font-weight: bold;
}

.Categories form {
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1rem 0;
  /* flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; 
  scrollbar-width: none;  */
}
/* .Categories form::-webkit-scrollbar {
  display: none; 
} */

.Personnalisable {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Personnalisable label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Personnalisable input[type="checkbox"] {
  display: none;
}

.Personnalisable input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}

/* =========================== */
.Informative {
  margin: 0 0.5em;
  padding: 0.8em 0;
  flex: 0 0 auto;
}

.Informative label {
  background: #bcbecc;
  color: #5b5855;
  font-size: 0.85rem;
  border-radius: 24px;
  padding: 10px 15px;
  cursor: pointer;
}

.Informative input[type="checkbox"] {
  display: none;
}

.Informative input[type="checkbox"]:checked + label {
  background: white;
  color: #5b5855;
}
