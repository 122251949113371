.RefInfoTitle {
  display: flex;
}
.RefInfoTitle h3 {
  margin-left: 8px;
}

.RefWrapper img {
  width: 100%;
  margin-top: 15px;
}
