.ViewSelector {
  display: flex;
  justify-content: space-between;
}
.ViewSelector p {
  border-radius: 30px;
  cursor: pointer;
  box-sizing: border-box;
}
.UnselectedView {
  color: #292f33;
  background: #f3f2f0;
  border: 1px solid #3b4578;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
}
.SelectedView {
  background: #3b4578;
  color: white;
  border: 1px solid #3b4578;
}
/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ViewSelector {
    width: 15rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
