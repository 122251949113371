.switch {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 60px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  box-shadow: 0px 0px 55px rgba(0, 0, 0, 0.1);
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";

  width: 110px;
  height: 59px;
  left: 0px;
  top: 0px;

  background: #e2e2e2;
  box-shadow: inset 0px -7px 0px #bebebe;
  border-radius: 20px;

  transition: 0.4s;
}

input:checked + .slider:before {
  background: #f5a623;
  box-shadow: inset 0px -7px 0px #d28e1e;
  border-radius: 20px;
  transform: translateX(110px);
}

input:checked + .off {
  color: #ffffff;
}
