.Wrapper {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.ProfileSetupContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.ProfileSetup {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ImgWrapper {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.ImgWrapper img {
  width: 100%;
}

.Title {
  font-size: 1.5em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.Title span {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}
.CircularProgress {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.QuestionContainer {
  height: 15rem;
  margin-bottom: 3.5rem;
}

/* START =========== Buttons */
.ButtonsContainer {
  position: absolute;
  left: 0;
  bottom: 2rem;
  right: 0;
  text-align: center;
}
.Button {
  display: inline-block;
  margin: 0 1rem;
}
.SubmitButton {
  line-height: 0;
  width: auto;
  padding: 0 12px;
  height: 48px;
  margin: 0 8px;
  font-size: 1.2rem;

  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 10px;

  outline: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
