.NavigationItems {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* Small tablets */
@media screen and (min-width: 620px) {
  .NavigationItems {
    flex-flow: row;
    width: 50vw;
  }

  .Participer {
    margin-left: 25px;
  }
}

/* Large tablets & laptops */
@media (min-width: 960px) {
  .NavigationItems {
    width: 45vw;
  }
}
