.Card {
  background-color: white;
  display: flex;
  border-radius: 14px;
  position: relative;
  max-width: 22em;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.SelectedCard {
  border: 2px solid #e57373;
}

.Thumbnail {
  width: 7em;
  height: 7em;
  flex: 0 0 7em;
  background-color: white;
  margin: 0.5em;
  border-radius: 14px;
  display: flex;
}
.Thumbnail img {
  border-radius: 14px;
  width: 100%;
}

.Main {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Titre {
  font-size: 1rem;
  padding-right: 0.5rem;
}
.Titre span {
  font-weight: 700;
}

.Description {
  margin-right: 4em;
  font-size: 0.6rem;
}

.Icon {
  background-color: #f3f2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.4rem;
  border-radius: 0 14px 14px 0;
}

.TagsContainer {
  display: flex;
}
.TagContainer {
  font-size: 0.6rem;
  color: white;
  width: fit-content;
  margin-right: 1rem;
}
.Tag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 40px;
}
.Tag p {
  padding: 0 0.2rem;
}

.Notification {
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.6rem;
  background-color: #38bb6e;
  display: flex;
  justify-content: center;
  align-items: center;
}
