.Modal {
  position: fixed;
  z-index: 500;
  background: white;
  border-radius: 9px;
  width: 90%;
  padding: 16px;
  left: 5%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}
