.ArticleCard {
  background: #ffffff;
  padding: 1rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 22rem;
}
.ArticleCard p {
  margin: 0;
  color: #292f33;
  font-size: 1rem;
}
.Emoji {
  margin-right: 0.5rem;
  font-size: 1.8rem;
}
.DeleteButton {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 600px) {
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ArticleCard {
    min-width: 23rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .ArticleCard {
    min-width: 20rem;
  }
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
