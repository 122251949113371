/* .CardList {
  margin-bottom: 2em;
}

.List {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* large tablets & laptops */
/* @media screen and (min-width: 768px) {
  .List {
    align-items: flex-start;
    width: 21em;
  }
} */

.CardList {
  margin-bottom: 2em;
}

.List {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  /* .List {
    align-items: flex-start;
    width: 21em;
  } */
}
