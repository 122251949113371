.FormTitle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.1rem;
  text-align: center;
}

.Form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.FormElement {
  margin: 5px 5px;
  width: 3.2em;
  height: 3.2em;
  position: relative;
}

.FormElement input[type="radio"] {
  display: none;
}

.FormElement label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15%;
  border: none;
  background-color: #3d9ec756;
  color: #777;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.FormElement input[type="radio"]:checked + label {
  background-color: #3d9dc7;
  color: white;
}
