.Toolbar {
  height: 57px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #ebecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
}

/* .Logo {
  height: 60%;
} */
.Logo h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #5b5855;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 619px) {
  .DesktopOnly {
    display: none;
  }
}

/* Small tablets */
@media (min-width: 620px) {
  .Toolbar {
    /* box-shadow: none; */
    height: 70px;
    padding: 0 40px;
    /* position: relative; */
    background-color: white;
  }
  .Logo h3 {
    font-size: 1.8rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 960px) {
  .Toolbar {
    /* height: 100px; */
    padding: 10px 80px;
  }
  .Logo h3 {
    font-size: 2rem;
  }
}

/* Large Tablets & laptops */
@media screen and (min-width: 1200px) {
  .Toolbar {
    padding: 10px 100px;
  }
}
