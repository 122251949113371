.SectionTitle {
  text-align: center;
}
.SectionTitle .Emoji {
  font-size: 2.8em;
}
.SectionTitle h3 {
  font-size: 1.5em;
}
.SectionSubtitle {
  font-weight: bold;
  font-size: 0.8em;
  margin: 0.8em 2em;
}

.EmojiText {
  display: flex;
  margin: 0 2em;
}
.EmojiText .Emoji {
  font-size: 1.2em;
}

.EmojiText p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.ParticiperButton {
  text-align: center;
  margin: 1.5em 0;
}

.Illustration {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.CarouselSection {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.CarouselSection::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.CarouselItem {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.CarouselItem:first-child {
  margin-left: 40px;
}
.CourselItem:last-child {
  margin-right: 20px;
}

.GrandesEtapes {
  display: flex;
  justify-content: center;
}
.GrandesEtapes img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.HeroImage {
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.HeroImage img {
  width: 100%;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .HeroImage {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
  }
  .HeroImage img {
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  .Section {
    margin: 0 5.5em;
  }
  .SectionTitle {
    display: flex;
    align-items: flex-end;
  }
  .SectionSubtitle {
    font-weight: bold;
    font-size: 0.9em;
    margin: 0.8em 0;
  }
  .ContactForm {
    margin: 0 auto;
    max-width: 40em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .HeroImage {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
  }
  .Section {
    margin: 0 20em;
  }
  .Illustration {
    width: 25em;
    height: 25em;
  }
}
