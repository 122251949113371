.OvalButton {
  display: flex;
  margin: 0 auto;
  padding: 20px 40px;
  border: 0;
  outline: 0;
  background: #ebecf0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 41px;
}

.OvalButton:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}
