.AddButton p {
  background: #ee9834;
  color: white;
  border: 1px solid #ee9834;
  cursor: pointer;
  padding: 0.625rem 1.25rem;
  border-radius: 30px;
  font-weight: 700;
  font-size: 0.875rem;
}
.ModalContent {
  background-color: #f3f2f0;
  padding: 2rem;
  border-radius: 10px;
  width: 30rem;
  outline: none;
}
.QuestionTitle {
  margin-top: 0.5em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.QuestionTitle p {
  color: #2a2f39;
  margin-left: 0.4em;
}
.TitleField {
  margin-top: 1rem;
}
.CloudIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.6rem;
  justify-content: center;
}
.Buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .ModalContent {
    width: 80%;
    outline: none;
  }
}
