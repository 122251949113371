.Main {
  padding: 0 1em;
  margin-bottom: 2em;
}

.Title {
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.Title span {
  font-size: 1.5rem;
  font-weight: bold;
}
.StatsIllustration {
  margin-top: 2em;
}
.StatsContent {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.StatsContent h3 {
  font-size: 1.4rem;
  font-weight: normal;
}
.StatsContent p {
  font-size: 2rem;
  font-weight: bold;
}
.Illustration {
  margin: 1em auto;
  width: 80%;
  max-width: 25em;
  display: flex;
  justify-content: center;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .Resultats {
    margin: 0 2em;
    margin-top: 1em;
  }
}
/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .Resultats {
    margin: 0 10em;
  }
}
