* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Wrapper {
  height: 100vh;
  background-color: #e5e5e5;
  overflow: hidden;
  overflow-y: auto;
}
.LoginContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

.Login {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
}

.ImgWrapper {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}
.ImgWrapper img {
  width: 100%;
}

.LoginTitle {
  font-size: 2em;
  text-align: left;
  line-height: 1.2em;
  margin-bottom: 40px;
}
.LoginTitle span {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ====FORM STYLING START====== */
.InputGroup {
  position: relative;
  width: 80%;
  margin-bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.Input {
  font-size: 18px;
  font-family: inherit;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  font-family: Arial, Helvetica, sans-serif;
}
.Input:focus {
  outline: none;
}
.Phone {
  padding-left: 45px;
}
.InputGroup i {
  position: absolute;
  font-size: 1.3em;
  top: 12px;
  left: -35px;
  color: #999;
  cursor: pointer;
}
.MUIInputGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.InputError {
  font-size: 0.85rem;
  color: #e0004d;
  margin-top: 0.5em;
}
/* LABEL ======= */
.InputLabel {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
/* active state */
.Input:focus ~ .InputLabel,
.Input:valid ~ .InputLabel {
  top: -20px;
  font-size: 14px;
  color: #003c71;
}
/* BOTTOM BARS ========= */
.Bar {
  position: relative;
  display: block;
  width: 100%;
}
.Bar:before,
.Bar:after {
  content: "";
  height: 2px;
  width: 0px;
  bottom: 1px;
  position: absolute;
  background: #003c71;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.Bar:before {
  left: 50%;
}
.Bar:after {
  right: 50%;
}
/* active state */
.Input:focus ~ .Bar:before,
.Input:focus ~ .Bar:after {
  width: 50%;
}

/* HIGHLIGHTER ============ */
.Highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
/* active state */
.Input:focus ~ .Highlight {
  animation: inputHighlighter 0.3s ease;
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
}
/* Animations */
@keyframes inputHighlighter {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-webkit-keyframes inputHighlighter {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #003c71;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* Indicatif ====== */
.Indicatif {
  position: absolute;
  font-size: 18px;
  top: 24%;
  left: 0;
  opacity: 0;
  font-family: Arial, Helvetica, sans-serif;
}
/* active state */
.Input:focus ~ .Indicatif,
.Input:valid ~ .Indicatif {
  opacity: 1;
}

/* ========= CGU CHECKBOX =========== */
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

.CGUGroup input[type="checkbox"] {
  display: none;
}
.CGUGroup input[type="checkbox"] + label:before {
  font-family: FontAwesome;
  display: inline-block;
}

.CGUGroup input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
.CGUGroup input[type="checkbox"]:checked + label:before {
  letter-spacing: 8px;
}

.CGUGroup input[type="checkbox"] + label:before {
  content: "\f096";
}
.CGUGroup input[type="checkbox"] + label:before {
  letter-spacing: 10px;
}

/* CGU Link ====== */
.CGUGroup label {
  color: #828489;
  font-size: 0.9em;
}
.CGUGroup span {
  cursor: pointer;
  /* font-weight: 700; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    rgba(0, 78, 124, 0.178) 40%
  );
}

/* ======SUBMIT BUTTON ====== */
.SubmitButtonContainer {
  margin-top: 40px;
}

/* Anon link */
.AnonLogin {
  margin-top: 1em;
  text-align: center;
}
