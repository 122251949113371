.Card {
  background-color: white;
  outline: none;
  overflow: scroll;
}

.CardHeader {
  display: flex;
  top: 0;
  left: 0;
  margin: 2rem 2.5rem;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  height: 3em;
  margin-bottom: 1.5em;
}

.CardTitle h3 {
  margin-top: 1em;
  font-size: 1.5rem;
  color: #292f33;
  font-weight: bold;
}
.CardTitle h4 {
  font-size: 1.3rem;
  color: #5b5855;
  font-weight: normal;
}

.ImagePreview {
  margin: 0.5em auto;
  width: 80%;
  max-width: 20em;
}
.ImagePreview img {
  width: 100%;
}

.Description {
  margin: 0.5rem 2.5rem;
  color: #898786;
  font-size: 0.9rem;
}

.CardContent {
  margin-top: 2rem;
  padding: 2rem 2.5em;
  background-color: #f3f2f0;
}

.Emoji {
  font-size: 1.8rem;
}

.QuestionsHeader {
  color: #3b4578;
  margin-top: 0.7em;
  margin-bottom: 1.5rem;
}

.QuestionTitle {
  margin-top: 0.5em;
  font-size: 1rem;
  display: flex;
  font-weight: 900;
  /* justify-content: center; */
  align-items: center;
}
.QuestionTitle p {
  color: #2a2f39;
  margin-left: 0.4em;
}

/* ============================== radio form */
.RadioForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.RadioElement {
  margin: 1em 0.8em;
}

.RadioElement label {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.RadioElement p {
  text-align: center;
  margin-top: 0.5em;
  font-size: 0.85em;
}

.RadioElement img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5em;
  /* border-radius: 50%; */
  /* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.14); */
}

.RadioElement input {
  display: none;
}

.RadioElement input[type="radio"]:checked + label img {
  border: 3px solid #e31869;
}
.RadioElement input[type="radio"]:checked + label p {
  font-weight: bold;
}

/* TESTING ====================================================================== */
/* .RadioForm .RadioElementTwo {
  margin: 10px 5px 5px 0;
  display: inline-block;
} */

.RadioFormTwo {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: left; */
}

.RadioElementTwo {
  margin: 1em 1em;
}

.RadioElementTwo input[type="radio"] {
  display: none;
}

.RadioElementTwo input[type="radio"] + label + p {
  text-align: center;
  width: 8em;
}

.RadioElementTwo input[type="radio"] + label {
  background-position: center center;
  background-size: contain;
  height: 8em;
  width: 8em;
  display: inline-block;
  position: relative;
  border: 4px solid rgb(196, 196, 196);
  border-radius: 5%;
}

.RadioElementTwo input[type="radio"] + label:hover {
  cursor: pointer;
}

.RadioElementTwo input[type="radio"]:checked + label {
  border-color: #e0004d;
}

.RadioElementTwo input[type="radio"] + label i {
  font-size: 12px;
  position: absolute;
  left: 9.5em;
  bottom: -8px;
  color: #fff;
  background-color: #e0004d;
  border-radius: 100%;
  border: 2px solid #e0004d;
  padding: 2px;
  display: none;
}

.RadioElementTwo input[type="radio"]:checked + label i {
  display: inline-block;
}
.RadioElementTwo input[type="radio"]:checked + label + p {
  font-weight: bold;
}

/* ============ END RADIO FORM */

.CommentSection {
  margin-top: 2rem;
  text-align: center;
}

.Buttons {
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
}

.MUIForm {
  margin-left: 2em;
}

.NCSection {
  margin-top: 1.1em;
}
.MapImage {
  margin-top: 0.85em;
  /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14); */
  /* border-radius: 10px; */
}

.Notices {
  margin: 2em 0;
  font-size: 0.6rem;
}

/* Desktops */
@media screen and (min-width: 910px) {
  .Card {
    padding: 0;
    border-radius: 10px;
    width: 80%;
    height: 90%;
    outline: none;
    display: flex;
  }
  .Card::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .Card {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .Description {
  }

  .LeftSection {
    background-color: white;
    width: 50%;
    padding: 1em;
    padding-bottom: 3em;
  }

  .RightSection {
    background-color: #f3f2f0;
    width: 50%;
    height: 100%;
    position: relative;
  }

  .CardContent {
    height: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
