.CardModal {
  position: fixed;
  z-index: 500;
  background: white;
  overflow-y: scroll;
  /* border-radius: 9px; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .CardModal {
    position: static;
    overflow-y: hidden;
    background-color: #f3f2f0;
    width: auto;
    height: auto;
    padding: 2em;
    border-radius: 14px;
    margin-left: 3em;
    margin-bottom: 2em;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1400px) {
  .CardModal {
    padding: 0;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
  }
}
