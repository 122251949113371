.ModalCreateReclamation {
  padding: 0;
  border-radius: 10px;
  padding: 1rem;
  outline: none;
  background-color: white;
  outline: none;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ModalCreateReclamation::-webkit-scrollbar {
  display: none;
}

.ModalContent {
  width: 100%;
  height: 100%;
  outline: none;
  background-color: #f3f2f0;
  padding: 2rem;
}

.ModalTitle {
  margin-top: -0.5rem;
  margin-bottom: 1.7rem;
  font-size: 0.9rem;
  font-weight: 700;
  color: #3b4578;
  display: flex;
  justify-content: space-between;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .ModalContent {
    position: relative;
    border-radius: 10px;
    width: 30rem;
    height: 90%;
    max-height: 50rem;
    outline: none;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
}

/* Very large screens */
@media screen and (min-width: 1500px) {
}
