.SubmitButton {
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 1em 1em;
  min-width: 10em;
  border: 0;
  outline: 0;
  font-size: 0.9rem;
  text-align: center;
  /* Primaire Rouge 1 */
  background: #e0004d;
  /* 6dp elevation */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 6px 10px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.SubmitButton:active {
  box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.SubmitButton:disabled {
  background-color: gray;
  color: #ccc;
  cursor: not-allowed;
}

/* Invalid state */
.Invalid i {
  transform: translate(-30vw, 11vh);
  transition: all linear 0.2s;
  opacity: 0;
}
.Invalid span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* transition: all linear 0.2s; */
}

/* Valid state */
.Valid i {
  color: #ffc300;
  font-size: 1.4em;
  margin-left: 0.4em;
  transition: all linear 0.2s;
  opacity: 1;
}

/* Loading state */
.Clicked i {
  transform: translate(100vw, -90vh);
  transition: all linear 1.3s;
}
