.HeaderImageWrapper {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.HeaderImageWrapper img {
  width: 80%;
}

.ProfilePage h1 {
  margin: 1rem 2rem;
}
.ProfilePage h3 {
  margin: 1rem 0 1rem 2rem;
}
.SubmitButton {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}
